export const phoneNumber = '+39 335 5444052';

export const emailAddress = 'e.gassebner(at)rolmail.net';

export const pages = {
  imprint: 'imprint',
  privacy: 'privacy'
};

export const customScreenMD = 835;

export const isBrowser = typeof window !== 'undefined';
